import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { PageHeader } from '@ant-design/pro-layout';
import {
  Table, Badge, Skeleton, Empty, Button, Form, Tooltip, Modal, Typography, Flex, message, Drawer, Divider, Select, Row, Col
} from 'antd';
import {
  FilePdfOutlined,
  DownloadOutlined,
  LoadingOutlined,
  CloudUploadOutlined,
  CheckCircleTwoTone,
  FilterTwoTone
} from '@ant-design/icons';
// import "./index.css";
import { axiosClient } from '../../apiClient';
// import { useDispatch } from "react-redux";
import {
  mascaraPhone,
  mascaraCnpj,
  mascaraCpf,
} from '../../functions/validation';

const { Link } = Typography;

export default function SellerListOrder() {
  const navigate = useNavigate();
  const location = useLocation();
  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingPDF, setLoadingPDF] = useState(false);
  const [visible, setVisible] = useState(false);
  const [pdfUrl, setPdfUrl] = useState(null);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(null);
  const [ordersChecked, setOrdersChecked] = useState(false);
  const [sendingOrderId, setSendingOrderId] = useState(null);
  const [filterDrawerVisible, setFilterDrawerVisible] = useState(false);
  const [sellerUsers, setSellerUsers] = useState([]);
  const [selectedSellerUser, setSelectedSellerUser] = useState(null);

  const [filterValues, setFilterValues] = useState({
    sellerUser: 'all'
  });


  const pathSegment = location.pathname.split("/").filter(Boolean).pop();
  const pathSegmentRef = useRef(pathSegment);

  const checkOrderOmie = (orderId) => {
    setSendingOrderId(orderId);
    message.loading('Verificando pedido...', 0);

    axiosClient.post('/integrations/omie/check-order', { orderId }, { withCredentials: true })
      .then(response => {
        message.destroy();
        const exists = response.data.exists;

        if (exists) {
          message.success('Pedido já existe no Omie', 4);
        } else {
          message.error('Pedido não encontrado no Omie', 4);
        }

        const updatedData = data.map(order =>
          order.id === orderId
            ? {
              ...order,
              order_info: {
                ...order.order_info,
                numero_pedido_integracao: exists ? order.order_info?.numero_pedido_integracao : null
              }
            }
            : order
        );

        setData(updatedData);
        setSendingOrderId(null);
      });
  };

  useEffect(() => {

    if ((pathSegmentRef.current !== pathSegment && page === 1) | (pathSegmentRef.current === pathSegment)) {
      setLoading(true);
      axiosClient
        .post(`/${pathSegment === 'orcamentos' ? 'quote' : 'order'}/list`,
          { page, perPage, filter: { selectedSellerUser } }, { withCredentials: true })
        .then((response) => {
          setTotalPages(response.data.total_pages);
          const values = response.data.orders.map((v) => ({ ...v, key: v.id }));
          setData(values);
          setLoading(false);
          pathSegmentRef.current = pathSegment;
        });
    }
  }, [page, perPage, pathSegment, selectedSellerUser]);

  useEffect(() => {
    setPage(1);
  }, [pathSegment]);

  const openPDF = (data) => {
    // const a = document.createElement("a");
    // a.setAttribute('href', URL.createObjectURL(new Blob([data], { type: "application/pdf" })));
    // a.setAttribute('target', '_blank');
    // a.click();
    // window.open(URL.createObjectURL(new Blob([response.data], { type: "application/pdf" })))
    setPdfUrl(
      URL.createObjectURL(new Blob([data], { type: 'application/pdf' })),
    );
    setVisible(true);
  };

  const downloadPDF = () => {
    const link = document.createElement('a');
    link.href = pdfUrl;
    link.target = '_self';
    link.download = 'pedido.pdf';
    document.body.append(link);
    link.click();
    link.remove();
  };

  const sendOrderOmie = (orderId) => {
    setSendingOrderId(orderId);
    message.loading('Enviando pedido...', 0);
    axiosClient.post(
      '/integrations/omie/send-order',
      { orderId },
      { withCredentials: true },
    )
      .then((response) => {
        message.destroy();
        setOrdersChecked(false);
        setSendingOrderId(null);
        if (response.data.status === "Success") {
          message.success(`Pedido enviado com sucesso! Código: ${response.data.numero_pedido}`, 4);
          setData((prevData) => {
            return prevData.map((order) => {
              if (order.id === orderId) {
                return {
                  ...order,
                  order_info: {
                    ...order.order_info,
                    numero_pedido_integracao: response.data.numero_pedido,
                  },
                  numero_pedido: response.data.numero_pedido,
                };
              }
              return order;
            });
          });
        }
      })
      .catch((error) => {
        message.destroy();
        setSendingOrderId(null);
        if (error.response && error.response.data && error.response.data.msg) {
          message.error(error.response.data.msg, 4);
        } else {
          message.error('Erro ao enviar o pedido.', 4);
        }
      });
  };

  const columns = [
    {
      title: 'Numero',
      width: '10%',
      dataIndex: 'number',
      ellipsis: true,
      responsive: ['sm'],
    },
    {
      title: 'Data',
      width: '12%',
      dataIndex: 'data',
      ellipsis: true,
      responsive: ['sm'],
    },
    {
      title: 'Cliente',
      render: (text, record) => (
        <>
          {record.buyer.tipo_pessoa == 'pf' ? (
            <Link>{(record.buyer.nome_fantasia || record.buyer.nome)}</Link>
          ) : (
            <Link>{record.buyer.razao_social}</Link>
          )}
        </>
      ),
      ellipsis: true,
      responsive: ['sm'],
    },
    {
      title: 'CPF/CNPJ',
      width: '15%',
      render: (text, record) => (
        <>
          {record.buyer.cnpj
            ? mascaraCnpj(record.buyer.cnpj)
            : mascaraCpf(record.buyer.cpf)}
        </>
      ),
      responsive: ['sm'],
    },
    {
      title: 'Total',
      width: '15%',
      dataIndex: 'total',
      render: (text, record) => (
        <>{`R$ ${parseFloat(record.total).toFixed(2)}`}</>
      ),
      responsive: ['sm'],
    },
    {
      title: 'Resumo',
      align: 'center',
      width: '10%',
      render: (record) => (
        <Flex justify="center" align="center">
          <Tooltip title="Abrir pdf">
            <Button
              shape="round"
              type="circle"
              onClick={() => {
                setLoadingPDF(record.id);
                axiosClient
                  .post(
                    '/order/resumo/pdf',
                    { id: record.id },
                    {
                      withCredentials: true,
                      responseType: 'arraybuffer',
                    },
                  )
                  .then((response) => {
                    openPDF(response.data);
                    setLoadingPDF(false);
                  });
              }}
            >
              {record.id == loadingPDF ? (
                <LoadingOutlined />
              ) : (
                <FilePdfOutlined />
              )}
            </Button>
          </Tooltip>
          {record.id === sendingOrderId ? (
            <Button shape="round" type="circle">
              <LoadingOutlined />
            </Button>
          ) : record.order_info?.numero_pedido_integracao?.length > 0 ? (
            <Tooltip title={`Número no ERP: ${parseInt(record.order_info?.numero_pedido_integracao)}`}>
              <Button
                shape="round"
                type="circle"
                onClick={() => checkOrderOmie(record.id)}
              >
                <CheckCircleTwoTone twoToneColor="#52c41a" />
              </Button>
            </Tooltip>
          ) : (
            pathSegment === 'pedidos' && (
              <Tooltip title="Enviar para o ERP">
                <Button
                  shape="round"
                  type="circle"
                  onClick={() => sendOrderOmie(record.id)}
                >
                  <CloudUploadOutlined />
                </Button>
              </Tooltip>
            )
          )}
        </Flex >
      ),
      ellipsis: false,
      responsive: ['sm'],
    },
    {
      align: 'left',
      title: 'Pedidos',
      render: (record) => (
        <>
          {`N°: ${record.number}`}
          <br />
          {record.data}
          <br />
          {record.buyer.tipo_pessoa == 'pf' ? (
            <Link>{(record.buyer.nome_fantasia || record.buyer.nome)}</Link>
          ) : (
            <Link>{record.buyer.razao_social}</Link>
          )}
          <br />
          {record.buyer.cnpj
            ? mascaraCnpj(record.buyer.cnpj)
            : mascaraCpf(record.buyer.cpf)}
          <br />
          {`R$ ${parseFloat(record.total).toFixed(2)}`}
          <br />
          <Flex>
            <Tooltip title="Abrir pdf">
              <Button
                shape="round"
                type="circle"
                onClick={() => {
                  setLoadingPDF(record.id);
                  axiosClient
                    .post(
                      '/order/resumo/pdf',
                      { id: record.id },
                      {
                        withCredentials: true,
                        responseType: 'arraybuffer',
                      },
                    )
                    .then((response) => {
                      openPDF(response.data);
                      setLoadingPDF(false);
                    });
                }}
              >
                {record.id == loadingPDF ? (
                  <LoadingOutlined />
                ) : (
                  <FilePdfOutlined />
                )}
              </Button>
            </Tooltip>
            {record.id === sendingOrderId ? (
              <Button shape="round" type="circle">
                <LoadingOutlined />
              </Button>
            ) : record.order_info?.numero_pedido_integracao?.length > 0 ? (
              <Tooltip title={`Número no ERP: ${parseInt(record.order_info?.numero_pedido_integracao)}`}>
                <Button
                  shape="round"
                  type="circle"
                  onClick={() => checkOrderOmie(record.id)}
                >
                  <CheckCircleTwoTone twoToneColor="#52c41a" />
                </Button>
              </Tooltip>
            ) : (
              pathSegment === 'pedidos' && (
                <Tooltip title="Enviar para o ERP">
                  <Button
                    shape="round"
                    type="circle"
                    onClick={() => sendOrderOmie(record.id)}
                  >
                    <CloudUploadOutlined />
                  </Button>
                </Tooltip>)
            )}
          </Flex >
        </>
      ),
      responsive: ['xs'],
      ellipsis: true,
    },
  ];

  useEffect(() => {
    axiosClient
      .get('/auth/list/seller-users', { withCredentials: true })
      .then((response) => {
        setSellerUsers(
          response.data.map((value) => ({
            value: value.id,
            label: value.nome,
          })),
        );
      });
  }, []);


  return (
    <>
      <PageHeader title={`${pathSegment === 'orcamentos' ? 'Orçamentos' : 'Pedidos Finalizados'}`} onBack={() => navigate('/admin')}
        extra={
          <Badge count={Object.values(filterValues).filter((value) => value !== 'all').length}>
            <Button onClick={() => setFilterDrawerVisible(true)} type="dashed" icon={<FilterTwoTone />}>Filtros</Button>
          </Badge>
        } />
      <Table
        columns={columns}
        dataSource={loading ? [] : data}
        locale={{
          emptyText: loading ? <Skeleton active /> : <Empty />,
        }}
        style={{ cursor: !loading ? 'pointer' : 'auto' }}
        pagination={{
          current: page,
          total: totalPages * perPage,
          pageSize: perPage,
        }}
        onChange={(val) => {
          window.scrollTo(0, 0);
          setPerPage(val.pageSize);
          setPage(val.current);
        }}
        onRow={(record, rowIndex) => ({
          onClick: (event) => {
            if (
              event.target.tagName != 'BUTTON'
              && event.target.tagName != 'path'
              && event.target.tagName != 'svg'
              && event.target.tagName != 'SPAN'
            ) {
              navigate(`/admin/vendas/${pathSegment}/${record.id}`);
            }
          },
        })}
      />
      {pdfUrl && (
        <Modal
          centered
          closable
          bodyStyle={{ height: '75vh' }}
          width="90%"
          title="Pedido"
          footer={(
            <Button onClick={() => downloadPDF()} type="primary">
              Download
              {' '}
              <DownloadOutlined />
            </Button>
          )}
          open={visible}
          onOk={() => setVisible(false)}
          onCancel={() => setVisible(false)}
          cancelText="Fechar"
          okButtonProps={{ style: { display: 'none' } }}
        >
          <iframe
            style={{ width: '100%', height: '70vh' }}
            src={pdfUrl}
            type="application/pdf"
          />
        </Modal>
      )}
      <Drawer
        title="Filtrar Dados"
        placement="right"
        onClose={() => setFilterDrawerVisible(false)}
        open={filterDrawerVisible}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={() => { setSelectedSellerUser(form.getFieldValue("sellerUser")); setFilterDrawerVisible(false); }}
          initialValues={filterValues}
        >
          <Form.Item
            name="sellerUser"
            label="Vendedor"
          >
            <Select
              onChange={(value) => setFilterValues({ ...filterValues, sellerUser: value })}
              options={[
                {
                  value: 'all',
                  label: 'Todos',
                },
                ...sellerUsers
              ]}
            />
          </Form.Item>
          <Form.Item>
            <div style={{ marginTop: 30 }}>
              <Row justify="center" gutter={[30, 12]}>
                <Col xs={18} sm={8}>
                  <Button block type="primary" htmlType="submit">
                    Aplicar
                  </Button>
                </Col>
              </Row>
            </div>
          </Form.Item>
        </Form>
      </Drawer>
    </>
  );
}
